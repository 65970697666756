import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { PLANT_SUPPORT_OPTIONS } from "../SeedInventory/SeedDialog";
import { useAuthContext } from "../common/context/AuthContext";
import { usePlantingContext } from "../common/context/PlantingDialogContext";
import { SeedInventoryService } from "../common/services/SeedInventoryService";
import { useDragDropPlantingContext } from "./DragDropPlantingContext";
import DraggablePlanting from "./DraggablePlanting";
import DroppableBedRow from "./DroppableBedRow";
import {
  getNumPlantsInArea,
  getRowWidthForTargetPlantCount,
} from "./LayoutCalculator";

function sortPlantings(plantings) {
  //  Sort by Support type, then by lowest to highest MAX space needed
  plantings.sort((a, b) => {
    return (
      a.primarySupport.localeCompare(b.primarySupport) ||
      a.inchesNeeded - b.inchesNeeded ||
      a.seedDetails.name.localeCompare(b.seedDetails.name)
    );
  });
}
export default function BedDesignDisplay({ bedDesign }) {
  const [seeds, setSeeds] = React.useState([]);
  const [plantingsToDisplay, setPlantingsToDisplay] = React.useState([]);
  const { currentUser } = useAuthContext();
  const actualPlantings = usePlantingContext().plantings;
  const [currentlyOverUnplanted, setCurrentlyOverUnplanted] = useState(0);
  const [plantingStartingType, setPlantingStartingType] =
    React.useState("ACTUAL");
  const { currentlyDraggedPlanting, setCurrentlyDraggedPlanting } =
    useDragDropPlantingContext();
  React.useEffect(() => {
    // Get list of seeds
    SeedInventoryService.loadSeeds(currentUser.primaryOrganization._id).then(
      (s) => setSeeds([...s])
    );
  }, [currentUser.primaryOrganization]);

  React.useEffect(() => {
    if (plantingStartingType === "ACTUAL" && actualPlantings) {
      setPlantingsToDisplay([...actualPlantings]);
    } else {
      if (seeds?.length) {
        const plantings = [];

        // Determine total inch width
        const totalInchesAllowed =
          bedDesign.beds.reduce((sum, bed) => sum + +bed.length, 0) * 12;
        let totalInchesUnused = totalInchesAllowed;

        // Determine space needed for each plant by seed quantity.
        for (let i = 0; i < seeds.length; i++) {
          seeds[i].maxInchesNeeded = getRowWidthForTargetPlantCount(
            36,
            seeds[i].spacingInches,
            seeds[i].seedsPerPacket * seeds[i].numPackets
          );
        }

        //  Sort by lowest to highest MAX space needed
        seeds.sort((a, b) => a.maxInchesNeeded - b.maxInchesNeeded);
        let maxSuggested = null;

        // If a plant needs less space than the estimated amount, adjust the unused inches & amount accordingly
        for (let i = 0; i < seeds.length; i++) {
          // Divide by total seed varieties for "desired width per section"
          let estInchesPerVariety = Math.floor(
            totalInchesUnused / (seeds.length - i)
          );
          const inchesToUse =
            maxSuggested ||
            Math.min(seeds[i].maxInchesNeeded, estInchesPerVariety);
          if (inchesToUse === estInchesPerVariety) {
            maxSuggested = inchesToUse;
          }
          let primarySupport = "None";
          if (seeds[i].supportNeeded?.length) {
            for (const supportOption of PLANT_SUPPORT_OPTIONS) {
              if (seeds[i].supportNeeded.includes(supportOption.value)) {
                primarySupport = supportOption.name;
                break;
              }
            }
          }
          seeds[i].inchesNeeded = inchesToUse;
          totalInchesUnused -= inchesToUse;
          plantings.push({
            _tempId: Math.random(),
            seedDetails: seeds[i],
            inchesNeeded: inchesToUse,
            actualSpacing: seeds[i].spacingInches,
            numPlantedOut: getNumPlantsInArea(
              inchesToUse,
              36,
              seeds[i].spacingInches
            ),
            primarySupport,
          });
        }

        sortPlantings(plantings);

        console.log(plantings);
        setPlantingsToDisplay([...plantings]);
      }
    }
  }, [seeds, bedDesign, plantingStartingType, actualPlantings]);

  function handleUnplantDrop(e) {
    e.preventDefault();
    setCurrentlyOverUnplanted(0);
    if (currentlyDraggedPlanting) {
      currentlyDraggedPlanting.onRelocateBed();
      const updatedPlantings = [
        ...plantingsToDisplay,
        currentlyDraggedPlanting,
      ];

      sortPlantings(updatedPlantings);
      setPlantingsToDisplay(updatedPlantings);
      setCurrentlyDraggedPlanting(null);
    }
  }

  return (
    <Box>
      <Button onClick={() => setPlantingStartingType("SUGGESTED")}>
        Generate Suggested Plantings
      </Button>
      <Grid
        container
        spacing={1}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleUnplantDrop}
        onDragEnter={(e) => {
          e.preventDefault();
          setCurrentlyOverUnplanted((val) => val + 1);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setCurrentlyOverUnplanted((val) => val - 1);
        }}
        style={{
          position: "relative",
          border: currentlyOverUnplanted
            ? `3px solid green`
            : `3px solid transparent`,
        }}
        className="droppable"
      >
        {plantingsToDisplay.map((planting, i) => (
          <React.Fragment key={planting._id || planting._tempId}>
            {(i === 0 ||
              planting.primarySupport !==
                plantingsToDisplay[i - 1].primarySupport) && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  Support Needed: {planting.primarySupport}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <DraggablePlanting
                planting={planting}
                onRelocate={() => {
                  plantingsToDisplay.splice(i, 1);
                  setPlantingsToDisplay([...plantingsToDisplay]);
                }}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {bedDesign &&
        bedDesign.beds.map((bed, i) => (
          <React.Fragment key={i}>
            {(i === 0 || bed.group !== bedDesign.beds[i - 1].group) && (
              <Typography variant="h6">{bed.group}</Typography>
            )}
            <DroppableBedRow bed={bed} key={i} />
          </React.Fragment>
        ))}
    </Box>
  );
}
