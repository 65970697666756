import { Add } from "@mui/icons-material";
import { Fab, MenuItem, Paper, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAuthContext } from "../common/context/AuthContext";
import { OrderService } from "../common/services/OrderService";
import OrderDialog, { getCostNumber } from "./OrderDialog";
import OrderSummary from "./OrderSummary";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("all");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [addingNewOrder, setAddingNewOrder] = useState(false);
  const { currentUser } = useAuthContext();

  const ordersToDisplay = orders.filter((o) =>
    filter === "all" ? true : filter === "sales" ? o.isSale : !o.isSale
  );
  React.useEffect(() => {
    OrderService.getOrders(currentUser.primaryOrganization._id).then(setOrders);
  }, [currentUser.primaryOrganization]);

  function beginNewOrder() {
    setAddingNewOrder(true);
  }

  function closeDialog() {
    setAddingNewOrder(false);
    setSelectedOrder(false);
  }

  function onDelete() {
    const idx = orders.findIndex((o) => selectedOrder._id === o._id);
    orders.splice(idx, 1);
    setOrders([...orders]);
    closeDialog();
  }

  const totalSpend = ordersToDisplay.reduce(
    (sum, o) =>
      sum +
      (o.isSale ? +getCostNumber(o.totalCost) : -getCostNumber(o.totalCost)),
    0
  );

  return (
    <Paper style={{ maxWidth: 500 }}>
      <Select
        style={{ minWidth: 300 }}
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      >
        <MenuItem value="all">All Transactions</MenuItem>
        <MenuItem value="sales">Sales</MenuItem>
        <MenuItem value="purchases">Purchases</MenuItem>
      </Select>
      {ordersToDisplay?.map((order, i) => (
        <OrderSummary
          order={order}
          key={order._id}
          setSelectedOrder={setSelectedOrder}
        />
      ))}
      <Typography variant="h6" style={{ textAlign: "right" }}>
        ${totalSpend.toFixed(2)}
      </Typography>
      <OrderDialog
        open={Boolean(addingNewOrder || selectedOrder)}
        orderToEdit={selectedOrder}
        onSave={(savedOrder) => {
          if (selectedOrder) {
            const idx = orders.findIndex((o) => selectedOrder._id === o._id);
            orders[idx] = savedOrder;
            setOrders([...orders]);
          } else {
            setOrders([...orders, savedOrder]);
            setSelectedOrder(savedOrder);
          }
          closeDialog();
        }}
        onDelete={onDelete}
        onCancel={closeDialog}
      />
      <Fab
        type="button"
        color="primary"
        aria-label="Add Order"
        onClick={beginNewOrder}
        style={{ position: "fixed", right: 25, bottom: 25 }}
      >
        <Add />
      </Fab>
    </Paper>
  );
}
