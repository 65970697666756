import axios from "axios";
import { getCommentFunctions } from "./GenericCommentService";
export const HARVEST_LOG_BASE_URL = process.env.REACT_APP_API_PATH + "/harvestLogs";

const commentFunctions = getCommentFunctions(HARVEST_LOG_BASE_URL);

export const HarvestLogService = {
  getHarvestLogs: (orgId) => axios.get(HARVEST_LOG_BASE_URL + "/organization/" + orgId).then((r) => r.data),
  saveHarvestLog: (harvestLog) => axios.post(HARVEST_LOG_BASE_URL, harvestLog).then((r) => r.data),
  deleteHarvestLogById: (harvestLogId) => axios.delete(HARVEST_LOG_BASE_URL + "/" + harvestLogId).then((r) => r.data),
  ...commentFunctions,
};
