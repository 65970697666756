import { Grid } from "@mui/material";
import React from "react";
import { useDragDropPlantingContext } from "./DragDropPlantingContext";
import DraggablePlanting from "./DraggablePlanting";

function getTotalInchesUsedInBed(bedPlantings) {
  let inchesUsed = 0;
  for (let i = 0; i < bedPlantings.length; i++) {
    inchesUsed += bedPlantings[i].inchesNeeded;
  }
  return inchesUsed;
}

export default function DroppableBedRow({ bed }) {
  const { currentlyDraggedPlanting, setCurrentlyDraggedPlanting } = useDragDropPlantingContext();
  const [currentlyOverBed, setCurrentlyOverBed] = React.useState(false);
  const [bedPlantings, setBedPlantings] = React.useState(bed.plantings || []);
  const widthInches = bed.width * 12;
  const lengthInches = bed.length * 12;

  function handleDrop(e) {
    e.preventDefault();
    setCurrentlyOverBed(false);
    if (currentlyDraggedPlanting) {
      currentlyDraggedPlanting.onRelocateBed();
      setBedPlantings([...bedPlantings, currentlyDraggedPlanting]);
      setCurrentlyDraggedPlanting(null);
    }
  }

  const isDragging = Boolean(currentlyDraggedPlanting);

  const canPlantingFitInBed =
    isDragging &&
    (bed.lengthInches || bed.length * 12) - getTotalInchesUsedInBed(bedPlantings) >=
      currentlyDraggedPlanting.inchesNeeded;
  const isPlantingAlreadyInBed =
    isDragging &&
    bedPlantings.findIndex(
      (p) =>
        (currentlyDraggedPlanting._id && currentlyDraggedPlanting._id === p._id) ||
        (currentlyDraggedPlanting._tempId && currentlyDraggedPlanting._tempId === p._tempId)
    ) >= 0;

  const canBeDroppedInto = isDragging && canPlantingFitInBed;
  return (
    <Grid
      container
      style={{
        backgroundColor: "#4b2006",
        height: widthInches,
        width: lengthInches,
        border: `1px solid ${currentlyDraggedPlanting && currentlyOverBed ? "red" : "black"}`,
        position: "relative",
        margin: 4,
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setCurrentlyOverBed(true);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setCurrentlyOverBed(false);
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={canBeDroppedInto ? handleDrop : null}
      className="droppable"
    >
      {bedPlantings.map((planting, i) => (
        <DraggablePlanting
          key={planting._id || planting._tempId}
          planting={planting}
          onRelocate={() => {
            bedPlantings.splice(i, 1);
            setBedPlantings([...bedPlantings]);
          }}
        />
      ))}
      {currentlyDraggedPlanting && !isPlantingAlreadyInBed && !canPlantingFitInBed && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            color: "#822",
            textAlign: "center",
            zIndex: "auto",
            fontSize: 24,
          }}
        >
          Not enough room in bed
        </div>
      )}
    </Grid>
  );
}
