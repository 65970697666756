import { Button, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../common/context/AuthContext";
import { LayoutService } from "../common/services/LayoutService";
import BedDesignDialog from "./BedDesignDialog";
import BedDesignDisplay from "./BedDesignDisplay";

export default function Layout() {
  const { currentUser } = useAuthContext();
  const [activeBedDesign, setActiveBedDesign] = useState();
  const [bedDesigns, setBedDesigns] = useState();
  const [showBedDesignDialog, setShowBedDesignDialog] = useState(false);

  useEffect(() => {
    LayoutService.getBedLayouts(currentUser.primaryOrganization._id).then((loadedDesigns) => {
      setBedDesigns(loadedDesigns);
      if (loadedDesigns.length > 0) {
        setActiveBedDesign(loadedDesigns[0]);
      }
    });
  }, [currentUser.primaryOrganization]);

  return (
    <Grid>
      {bedDesigns?.length > 0 && (
        <Select
          style={{ minWidth: 300 }}
          value={activeBedDesign?._id || ""}
          onChange={(e) => {
            const bedLayout = bedDesigns.find((bd) => bd._id === e.target.value);
            setActiveBedDesign(bedLayout);
          }}
        >
          {bedDesigns.map((bedDesign) => (
            <MenuItem value={bedDesign._id} key={bedDesign._id}>
              {bedDesign.name}
            </MenuItem>
          ))}
        </Select>
      )}
      <Button
        onClick={() => {
          setShowBedDesignDialog(true);
          setActiveBedDesign();
        }}
      >
        Begin New Layout
      </Button>
      {activeBedDesign && (
        <Button
          onClick={() => {
            setShowBedDesignDialog(true);
          }}
        >
          Edit Bed Design
        </Button>
      )}
      <BedDesignDialog
        open={showBedDesignDialog}
        onClose={(updatedBedDesign) => {
          if (updatedBedDesign?.beds) {
            setBedDesigns([...bedDesigns, updatedBedDesign]);
            setActiveBedDesign(updatedBedDesign);
          }
          setShowBedDesignDialog(false);
        }}
        bedDesignToEdit={activeBedDesign}
      />
      {activeBedDesign && <BedDesignDisplay bedDesign={activeBedDesign} />}
    </Grid>
  );
}
