import { Cancel, Delete, Save } from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    Card,
    CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import CommentsSection from "../comments/CommentsSection";
import { MyDateField } from "../common/components/MyDateField";
import { useAuthContext } from "../common/context/AuthContext";
import { HarvestLogService } from "../common/services/HarvestLogService";
import { SeedInventoryService } from "../common/services/SeedInventoryService";
import { MyTextField } from "./../common/components/MyTextField";

export default function HarvestLogDialog({
  open,
  harvestLog,
  onSaveChanges,
  onCancel,
  onDelete,
}) {
  const [allSeeds, setAllSeeds] = React.useState([]);
  const { isAdminForCurrentOrg, currentUser } = useAuthContext();
  React.useEffect(() => {
    SeedInventoryService.loadSeeds(currentUser.primaryOrganization._id).then(
      (s) => setAllSeeds([...s])
    );
  }, [currentUser.primaryOrganization]);

  const [seedDetailsInputValue, setSeedDetailsInputValue] = useState(
    harvestLog?.seedDetails?.name || ""
  );
  const [seedDetails, setSeedDetails] = useState(
    harvestLog?.seedDetails || null
  );
  const [harvestDate, setHarvestDate] = useState(harvestLog?.harvestDate);
  const [stemCount, setStemCount] = useState(harvestLog?.stemCount);
  const [plantName, setPlantName] = useState(harvestLog?.plantName);
  const [quality, setQuality] = useState(harvestLog?.quality);
  const [addAnother, setAddAnother] = useState(false);
  React.useEffect(() => {
    setSeedDetailsInputValue(harvestLog?.seedDetails?.name || "");
    setSeedDetails(harvestLog?.seedDetails || null);
    setHarvestDate(harvestLog?.harvestDate || null);
    setStemCount(harvestLog?.stemCount || null);
    setPlantName(harvestLog?.plantName || "");
    setQuality(harvestLog?.quality || "");
  }, [harvestLog]);

  function deleteHarvestLog() {
    if (
      window.confirm(
        "Are you sure you want to delete this harvest log? [_id: " +
          harvestLog._id +
          "]?"
      )
    ) {
      HarvestLogService.deleteHarvestLogById(harvestLog._id)
        .then(() => onDelete(harvestLog._id))
        .catch((e) => {
          console.error(e);
          alert("Error deleting harvestLog!");
        });
    }
  }

  function saveHarvestLog() {
    const harvestLogToSave = {
      _id: harvestLog._id,
      seedDetails,
      harvestDate,
      stemCount: stemCount ? +stemCount : null,
      plantName,
      quality,
      organization: currentUser.primaryOrganization,
    };
    HarvestLogService.saveHarvestLog(harvestLogToSave)
      .then((savedHarvestLog) => onSaveChanges(savedHarvestLog, addAnother))
      .catch((e) => {
        console.error(e);
        alert("Error saving harvestLog!");
      });
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
      <DialogTitle>
        Harvest Log Info{" "}
        {harvestLog?._id &&
          ` - ${
            seedDetails?.name ||
            harvestLog.seedDetails?.name ||
            harvestLog.plantName
          }`}
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            saveHarvestLog();
          }}
        >
          <Grid container spacing={2} sx={{ mt: -1 }}>
            <Grid container spacing={2} item xs={12} md={9}>
              <Grid item xs={12}>
                <Autocomplete
                  autoFocus={true}
                  id="seed-details-for-harvest-log"
                  options={allSeeds.sort((a, b) => {
                    const plantTypeCompare = -b.typeOfPlant?.localeCompare(
                      a.typeOfPlant
                    );
                    if (plantTypeCompare === 0) {
                      return -b.name?.localeCompare(a.name);
                    }
                    return -plantTypeCompare;
                  })}
                  inputValue={seedDetailsInputValue}
                  onInputChange={(e, val) => setSeedDetailsInputValue(val)}
                  groupBy={(option) => option.typeOfPlant}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Seed Details" />
                  )}
                  value={seedDetails}
                  onChange={(e, val) => setSeedDetails(val)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyDateField
                  label="Harvest Date"
                  value={harvestDate}
                  setValue={(val) => {
                    setHarvestDate(val);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Stem Count"
                  value={stemCount}
                  setValue={setStemCount}
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Quality"
                  value={quality}
                  setValue={setQuality}
                />
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <MyTextField
                    label="Plant Name"
                    value={plantName}
                    setValue={setPlantName}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4} md={3}>
              <Grid item xs={12}>
                <Card style={{ position: "relative", width: "100%" }}>
                  {seedDetails?.picUrl && (
                    <CardMedia
                      component="img"
                      style={{ width: "100%" }}
                      image={seedDetails.picUrl}
                      alt={seedDetails.name}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <input type="submit" style={{ display: "none" }} />
        </form>
        {harvestLog?._id && (
          <CommentsSection
            baseId={harvestLog._id}
            commentService={HarvestLogService}
          />
        )}
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          type="button"
          onClick={onCancel}
          startIcon={<Cancel />}
          color="warning"
        >
          Cancel
        </Button>
        {harvestLog?._id &&
          (isAdminForCurrentOrg ||
            harvestLog?.createdBy === currentUser?._id) && (
            <Button
              type="button"
              onClick={deleteHarvestLog}
              startIcon={<Delete />}
              color="error"
            >
              Delete
            </Button>
          )}
        <FormControlLabel
          control={
            <Checkbox
              checked={addAnother}
              onChange={(e) => setAddAnother(e.target.checked)}
              color="primary"
            />
          }
          label="Add Another?"
        />
        <Button type="button" startIcon={<Save />} onClick={saveHarvestLog}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
