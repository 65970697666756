import { Cancel, CopyAll, Delete, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MyTextField } from "../common/components/MyTextField";
import { useAuthContext } from "../common/context/AuthContext";
import { SeedInventoryService } from "../common/services/SeedInventoryService";

export function ColorChip({ color, onDelete }) {
  return <Chip style={{ backgroundColor: color }} onDelete={onDelete} />;
}

export const CLIMATE_OPTIONS = [
  { value: "FrostTolerant", name: "Frost Tolerant" },
  { value: "CoolWeather", name: "Cool Weather" },
  { value: "WarmWeather", name: "Warm Weather" },
];

export const PLANT_SUPPORT_OPTIONS = [
  { value: "HorizontalNetting", name: "Horizontal Netting" },
  { value: "VerticalTrellising", name: "Vertical Trellising" },
  { value: "Corralling", name: "Corralling" },
  { value: "Staking", name: "Staking" },
];

export const PLANT_TYPE_OPTIONS = [
  { value: "primary", name: "Focal Flower" },
  { value: "secondary", name: "Secondary Flower" },
  { value: "filler", name: "Bouquet Filler" },
  { value: "edible", name: "Edible" },
];

export default function SeedDialog({
  open,
  seed,
  onSaveChanges,
  onCancel,
  onDelete,
  onDuplicate,
}) {
  const [name, setName] = useState(seed?.name);
  const [seedsPerPacket, setSeedsPerPacket] = useState(seed?.seedsPerPacket);
  const [numPackets, setNumPackets] = useState(seed?.numPackets);
  const [minDtm, setMinDtm] = useState(seed?.minDtm);
  const [maxDtm, setMaxDtm] = useState(seed?.maxDtm);
  const [seedDepth, setSeedDepth] = useState(seed?.seedDepth);
  const [spacingInches, setSpacingInches] = useState(seed?.spacingInches);
  const [startIndoorsMinWeeks, setStartIndoorsMinWeeks] = useState(
    seed?.startIndoorsMinWeeks
  );
  const [startIndoorsMaxWeeks, setStartIndoorsMaxWeeks] = useState(
    seed?.startIndoorsMaxWeeks
  );
  const [estMinStemsPerPlant, setEstMinStemsPerPlant] = useState(
    seed?.estMinStemsPerPlant
  );
  const [estMaxStemsPerPlant, setEstMaxStemsPerPlant] = useState(
    seed?.estMaxStemsPerPlant
  );
  const [germinationNotes, setGerminationNotes] = useState(
    seed?.germinationNotes
  );
  const [cultivationNotes, setCultivationNotes] = useState(
    seed?.cultivationNotes
  );
  const [picUrl, setPicUrl] = useState(seed?.picUrl);
  const [storeUrl, setStoreUrl] = useState(seed?.storeUrl);
  const [typeOfPlant, setTypeOfPlant] = useState(seed?.typeOfPlant);
  const [plantColors, setPlantColors] = useState(seed?.plantColors || []);
  const [supportNeeded, setSupportNeeded] = useState(seed?.supportNeeded || []);
  const [climateTags, setClimateTags] = useState(seed?.climateTags || []);
  const { currentUser, isAdminForCurrentOrg } = useAuthContext();

  React.useEffect(() => {
    if (seed) {
      setName(seed.name);
      setSeedsPerPacket(seed.seedsPerPacket);
      setNumPackets(seed.numPackets);
      setMinDtm(seed.minDtm);
      setMaxDtm(seed.maxDtm);
      setSeedDepth(seed.seedDepth);
      setSpacingInches(seed.spacingInches);
      setStartIndoorsMinWeeks(seed.startIndoorsMinWeeks);
      setStartIndoorsMaxWeeks(seed.startIndoorsMaxWeeks);
      setEstMinStemsPerPlant(seed.estMinStemsPerPlant);
      setEstMaxStemsPerPlant(seed.estMaxStemsPerPlant);
      setGerminationNotes(seed.germinationNotes);
      setCultivationNotes(seed.cultivationNotes);
      setPicUrl(seed.picUrl);
      setStoreUrl(seed.storeUrl);
      setTypeOfPlant(seed.typeOfPlant);
      setPlantColors(seed.plantColors || []);
      setSupportNeeded(seed.supportNeeded || []);
      setClimateTags(seed.climateTags || []);
    }
  }, [seed]);

  function getEstimatedStemCount() {
    if (!estMinStemsPerPlant) {
      return "";
    }
    if (estMinStemsPerPlant === estMaxStemsPerPlant) {
      if (estMinStemsPerPlant === 1) {
        return ": 1";
      } else {
        return `: ~${estMinStemsPerPlant}`;
      }
    } else {
      return `: ~${estMinStemsPerPlant}-${estMaxStemsPerPlant}`;
    }
  }

  function deleteSeedDetails() {
    if (
      window.confirm(
        "Are you sure you want to delete " + name + " [_id: " + seed._id + "]?"
      )
    ) {
      SeedInventoryService.deleteSeedById(seed._id)
        .then(onDelete)
        .catch((e) => {
          console.error(e);
          alert("Error deleting seed!");
        });
    }
  }

  function saveSeedDetails() {
    const upsertedSeedDetails = {
      _id: seed._id,
      name,
      seedsPerPacket: seedsPerPacket ? +seedsPerPacket : null,
      numPackets: numPackets ? +numPackets : null,
      minDtm: minDtm ? +minDtm : null,
      maxDtm: maxDtm ? +maxDtm : null,
      spacingInches: spacingInches ? +spacingInches : null,
      seedDepth: seedDepth ? +seedDepth : null,
      startIndoorsMinWeeks: startIndoorsMinWeeks ? +startIndoorsMinWeeks : null,
      startIndoorsMaxWeeks: startIndoorsMaxWeeks ? +startIndoorsMaxWeeks : null,
      estMinStemsPerPlant: estMinStemsPerPlant ? +estMinStemsPerPlant : null,
      estMaxStemsPerPlant: estMaxStemsPerPlant ? +estMaxStemsPerPlant : null,
      germinationNotes,
      cultivationNotes,
      picUrl,
      storeUrl,
      typeOfPlant,
      plantColors,
      supportNeeded,
      climateTags,
      organization: currentUser.primaryOrganization,
    };
    SeedInventoryService.upsertSeedDetails(upsertedSeedDetails)
      .then(onSaveChanges)
      .catch((e) => {
        console.error(e);
        alert("Error saving details!");
      });
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
      <DialogTitle>Seed Details</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveSeedDetails();
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container spacing={2} item xs={12} md={9}>
              <Grid item xs={12} md={8}>
                <MyTextField
                  label="Name"
                  value={name}
                  setValue={setName}
                  autoFocus
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="plant-type-label">Plant Type</InputLabel>
                  <Select
                    labelId="plant-type-label"
                    id="plant-type"
                    value={typeOfPlant}
                    label="Plant Type"
                    onChange={(e) => setTypeOfPlant(e.target.value)}
                    required
                  >
                    {PLANT_TYPE_OPTIONS.map((opt) => (
                      <MenuItem value={opt.value} key={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Seeds Per Packet"
                  value={seedsPerPacket}
                  setValue={setSeedsPerPacket}
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Number of Packets"
                  value={numPackets}
                  setValue={setNumPackets}
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="DTM (min)"
                  value={minDtm}
                  setValue={setMinDtm}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="DTM (max)"
                  value={maxDtm}
                  setValue={setMaxDtm}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Seed Depth (Inches)"
                  value={seedDepth}
                  setValue={setSeedDepth}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Spacing (Inches)"
                  value={spacingInches}
                  setValue={setSpacingInches}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Start Indoors Weeks (min)"
                  value={startIndoorsMinWeeks}
                  setValue={setStartIndoorsMinWeeks}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <MyTextField
                  label="Start Indoors Weeks (max)"
                  value={startIndoorsMaxWeeks}
                  setValue={setStartIndoorsMaxWeeks}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel id="support-needed-label">
                      Support Needed
                    </InputLabel>
                    <Select
                      labelId="support-needed-label"
                      id="support-needed"
                      value={supportNeeded}
                      label="Support Neeted"
                      onChange={(e) => {
                        const val = e.target.value;
                        setSupportNeeded(
                          typeof val === "string" ? val.split(",") : val
                        );
                      }}
                      multiple
                    >
                      {PLANT_SUPPORT_OPTIONS.map((supportOpt) => (
                        <MenuItem
                          value={supportOpt.value}
                          key={supportOpt.value}
                        >
                          {supportOpt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth>
                    <InputLabel id="climate-tags-label">
                      Preferred Climate
                    </InputLabel>
                    <Select
                      labelId="climate-tags-label"
                      id="climate-tags"
                      value={climateTags}
                      label="Preferred Climate"
                      onChange={(e) => {
                        const val = e.target.value;
                        setClimateTags(
                          typeof val === "string" ? val.split(",") : val
                        );
                      }}
                      multiple
                    >
                      {CLIMATE_OPTIONS.map((supportOpt) => (
                        <MenuItem
                          value={supportOpt.value}
                          key={supportOpt.value}
                        >
                          {supportOpt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <InputLabel id="est-stem-count-label">
                    Est Stems/Plant{getEstimatedStemCount()}
                  </InputLabel>
                  <Slider
                    getAriaLabel={() => "Estimated stem count"}
                    value={[estMinStemsPerPlant, estMaxStemsPerPlant]}
                    onChange={(e, newVal) => {
                      setEstMinStemsPerPlant(Math.min(newVal[0], newVal[1]));
                      setEstMaxStemsPerPlant(Math.max(newVal[0], newVal[1]));
                    }}
                    valueLabelDisplay="auto"
                    min={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    label="Germination Notes"
                    value={germinationNotes}
                    setValue={setGerminationNotes}
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    label="Cultivation Notes"
                    value={cultivationNotes}
                    setValue={setCultivationNotes}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MyTextField
                    label="Pic URL"
                    value={picUrl}
                    setValue={setPicUrl}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MyTextField
                    label="Link to Item"
                    value={storeUrl}
                    setValue={setStoreUrl}
                    fullWidth
                  />
                  {storeUrl && (
                    <Link href={storeUrl} underline="hover" target="_blank">
                      Click to view original site
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12} md={3}>
              <Grid item xs={12}>
                <Card style={{ position: "relative", width: "100%" }}>
                  {picUrl && (
                    <CardMedia
                      component="img"
                      style={{ width: "100%" }}
                      image={picUrl}
                      alt={name}
                    />
                  )}
                  <input
                    type="color"
                    name="currentColor"
                    onChange={(e) =>
                      setPlantColors([...plantColors, e.target.value])
                    }
                  />
                  <Typography variant="body2">Color Picker</Typography>
                  {plantColors.map((color, i) => (
                    <ColorChip
                      key={i}
                      color={color}
                      onDelete={() => {
                        plantColors.splice(i, 1);
                        setPlantColors([...plantColors]);
                      }}
                    />
                  ))}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="button"
            onClick={onCancel}
            startIcon={<Cancel />}
            color="warning"
          >
            Cancel
          </Button>
          {seed?._id && isAdminForCurrentOrg && (
            <Button
              type="button"
              onClick={deleteSeedDetails}
              startIcon={<Delete />}
              color="error"
            >
              Delete
            </Button>
          )}
          {seed?._id && (
            <Button
              type="button"
              onClick={() => onDuplicate(seed)}
              startIcon={<CopyAll />}
              color="info"
            >
              Duplicate
            </Button>
          )}
          <Button type="submit" startIcon={<Save />}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
