import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { getBgColorString } from "../FloralMixCalendar/PlantCalendarRow";
import { usePlantingContext } from "../common/context/PlantingDialogContext";
import { useDragDropPlantingContext } from "./DragDropPlantingContext";

export default function DraggablePlanting({ planting, onRelocate }) {
  const { currentlyDraggedPlanting, setCurrentlyDraggedPlanting } = useDragDropPlantingContext();
  const { editPlanting } = usePlantingContext();

  return (
    <Tooltip title={(planting.numPlantedOut || planting.numSeeded) + " " + planting.seedDetails.name}>
      <Box
        draggable
        onDragStart={(e) => {
          planting.onRelocateBed = onRelocate;
          setCurrentlyDraggedPlanting(planting);
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragEnd={(e) => {
          setTimeout(() => setCurrentlyDraggedPlanting(null));
        }}
        key={planting._id || planting._tempId}
        style={{
          height: 36,
          background: getBgColorString(planting.seedDetails?.plantColors),
          opacity:
            (currentlyDraggedPlanting?._id && currentlyDraggedPlanting?._id === planting._id) ||
            (currentlyDraggedPlanting?._tempId && currentlyDraggedPlanting?._tempId === planting._tempId)
              ? 0.3
              : 1,
          width: planting.inchesNeeded,
          cursor: "grab",
          textAlign: "center",
          padding: 6,
          position: "relative",
        }}
        onClick={() => editPlanting(planting)}
      >
        {planting.primarySupport === "Horizontal Netting" && (
          <Box
            sx={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundSize: `10px 10px`,
              backgroundImage: `linear-gradient(to right, #925706 2px, transparent 2px),
          linear-gradient(to bottom, #925706 2px, transparent 2px)`,
              zIndex: "auto",
            }}
          />
        )}
        {planting.primarySupport === "Vertical Trellising" && (
          <Box
            sx={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundSize: `10px 10px`,
              backgroundImage: `linear-gradient(to right, #332204 3px, transparent 3px)`,
              zIndex: "auto",
            }}
          />
        )}
        <Typography
          style={{
            maxWidth: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "fit-content",
            margin: "auto",
            position: "relative",
            zIndex: "auto",
          }}
        >
          {planting.seedDetails.name}
        </Typography>
      </Box>
    </Tooltip>
  );
}
