import {
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React from "react";
export default function HarvestLogSummary({
  harvestLog,
  deleteHarvestLog,
  editHarvestLog,
}) {
  return (
    <ListItemButton
      onClick={() => editHarvestLog(harvestLog)}
      style={{
        display: "inline-flex",
        border: "1px solid #CCC",
        borderRadius: 8,
        margin: 4,
        backgroundColor: "#FFF",
      }}
    >
      <ListItemIcon>
        <img
          src={harvestLog.seedDetails.picUrl}
          alt={harvestLog.seedDetails.name}
          style={{ maxWidth: 40, maxHeight: 40, borderRadius: 20 }}
        />
      </ListItemIcon>
      <ListItemText
        primary={harvestLog.seedDetails?.name}
        secondary={harvestLog.stemCount}
      ></ListItemText>
    </ListItemButton>
  );
}
