import { Cancel, Check, Delete, Save } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MyTextField } from "../common/components/MyTextField";
import { useAuthContext } from "../common/context/AuthContext";
import { LayoutService } from "../common/services/LayoutService";

export default function BedDesignDialog({ bedDesignToEdit, open, onClose }) {
  const { currentUser, isAdminForCurrentOrg } = useAuthContext();
  const [beds, setBeds] = useState([]);
  const [showNewBed, setShowNewBed] = useState(false);
  const [name, setName] = useState("");
  const [bedWidth, setBedWidth] = useState("");
  const [bedLength, setBedLength] = useState("");
  const [numBeds, setNumBeds] = useState("");
  const [bedGroup, setBedGroup] = useState("");

  useEffect(() => {
    if (open) {
      setBeds(bedDesignToEdit?.beds || []);
      setBedWidth("");
      setName(bedDesignToEdit?.name || "");
      setBedLength("");
      setNumBeds("");
      setBedGroup("");
      setShowNewBed(false);
    }
  }, [open, bedDesignToEdit]);

  function saveBedLayout() {
    LayoutService.saveBedLayout({
      name,
      beds,
      organization: currentUser.primaryOrganization,
    }).then((savedBedLayout) => {
      onClose(savedBedLayout);
    });
  }

  function submitNewBed(e) {
    e.preventDefault();
    const bedsToAdd = [];
    for (let i = 0; i < +numBeds; i++) {
      bedsToAdd.push({ width: bedWidth, length: bedLength, group: bedGroup });
    }
    setBeds([...beds, ...bedsToAdd]);
    setShowNewBed(false);
    setBedWidth("");
    setBedLength("");
    setNumBeds("");
    setBedGroup("");
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Design Your Beds</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyTextField label="Name of Growing Space" value={name} setValue={setName} />
          </Grid>
          {beds.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="body1">No beds found.</Typography>
            </Grid>
          ) : (
            <Grid item>
              {beds.map((bed, i) => (
                <React.Fragment key={i}>
                  {(i === 0 || bed.group !== beds[i - 1].group) && <Typography variant="h6">{bed.group}</Typography>}
                  <div style={{ margin: 8 }}>
                    Bed {i} - {bed.width}' x {bed.length}'
                  </div>
                </React.Fragment>
              ))}
            </Grid>
          )}
          <Grid item>
            {showNewBed ? (
              <form onSubmit={submitNewBed}>
                <Grid container spacing={1}>
                  <Grid item>
                    <MyTextField
                      autoFocus
                      label="Bed Width (ft)"
                      value={bedWidth}
                      setValue={setBedWidth}
                      type="number"
                      step="0.1"
                      required
                      helperText={bedWidth ? bedWidth * 12 + " in." : undefined}
                    />
                  </Grid>
                  <Grid item>
                    <MyTextField
                      label="Bed Length (ft)"
                      value={bedLength}
                      setValue={setBedLength}
                      type="number"
                      step="0.1"
                      helperText={bedLength ? bedLength * 12 + " in." : undefined}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <MyTextField
                      label="Number of Beds to Add"
                      value={numBeds}
                      setValue={setNumBeds}
                      type="number"
                      step="1"
                      required
                    />
                  </Grid>
                  <Grid item>
                    <MyTextField label="Bed Group" value={bedGroup} setValue={setBedGroup} required />
                  </Grid>
                  <Grid item>
                    <IconButton type="submit">
                      <Check />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Button onClick={() => setShowNewBed(true)}>Add New Bed(s)</Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button type="button" onClick={onClose} startIcon={<Cancel />} color="warning">
          Cancel
        </Button>
        {bedDesignToEdit?._id && (currentUser._id === bedDesignToEdit?.createdBy || isAdminForCurrentOrg) && (
          <Button type="button" onClick={() => {}} startIcon={<Delete />} color="error">
            Delete
          </Button>
        )}
        <Button type="button" onClick={saveBedLayout} startIcon={<Save />}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
