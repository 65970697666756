import { Add } from "@mui/icons-material";
import { Fab, List, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useAuthContext } from "../common/context/AuthContext";
import { HarvestLogService } from "../common/services/HarvestLogService";
import HarvestLogDialog from "./HarvestLogDialog";
import HarvestLogSummary from "./HarvestLogSummary";

export default function HarvestLogs() {
  const [harvestLogs, setHarvestLogs] = React.useState([]);
  const { currentUser } = useAuthContext();
  const [harvestLogToEdit, setHarvestLogToEdit] = React.useState(null);
  function onClickAddHarvestLog({ harvestDate }) {
    setHarvestLogToEdit({
      _id: undefined,
      harvestDate: harvestDate || moment().format("YYYY-MM-DD"),
      stemCount: 0,
      seedDetails: null,
      quality: "",
      plantName: "",
    });
  }

  function onClickEditHarvestLog(harvestLog) {
    setHarvestLogToEdit(harvestLog);
  }

  function onSaveChanges(harvestLog, addAnother) {
    const idx = harvestLogs.findIndex((h) => h._id === harvestLog._id);
    if (idx === -1) {
      setHarvestLogs([...harvestLogs, harvestLog]);
    } else {
      setHarvestLogs(
        harvestLogs.map((h) => (h._id === harvestLog._id ? harvestLog : h))
      );
    }
    if (addAnother) {
      onClickAddHarvestLog({ harvestDate: harvestLog.harvestDate });
    } else {
      setHarvestLogToEdit(null);
    }
  }
  function deleteHarvestLog(harvestLogId) {
    setHarvestLogs(harvestLogs.filter((h) => h._id !== harvestLogId));
    setHarvestLogToEdit(null);
  }

  React.useEffect(() => {
    HarvestLogService.getHarvestLogs(currentUser.primaryOrganization._id).then(
      setHarvestLogs
    );
  }, [currentUser.primaryOrganization._id]);
  return (
    <div>
      <List dense>
        {harvestLogs.map((harvestLog, i) => (
          <React.Fragment key={harvestLog._id}>
            {(i === 0 ||
              harvestLog.harvestDate !== harvestLogs[i - 1].harvestDate) && (
              <Typography variant="h6">
                Harvested on {moment(harvestLog.harvestDate).format("l")}
              </Typography>
            )}
            <HarvestLogSummary
              harvestLog={harvestLog}
              editHarvestLog={onClickEditHarvestLog}
              key={harvestLog._id}
            />
          </React.Fragment>
        ))}
      </List>

      <HarvestLogDialog
        open={Boolean(harvestLogToEdit)}
        harvestLog={harvestLogToEdit}
        onCancel={() => setHarvestLogToEdit(null)}
        onSaveChanges={onSaveChanges}
        onDelete={deleteHarvestLog}
      />
      <Fab
        type="button"
        color="primary"
        aria-label="Add Harvest Log"
        onClick={onClickAddHarvestLog}
        style={{ position: "fixed", right: 25, bottom: 25 }}
      >
        <Add />
      </Fab>
    </div>
  );
}
