import axios from "axios";
import { getCommentFunctions } from "./GenericCommentService";

const ORDER_BASE_URL = process.env.REACT_APP_API_PATH + "/orders";

const commentFunctions = getCommentFunctions(ORDER_BASE_URL);

export const OrderService = {
  getOrders: (orgId) => axios.get(ORDER_BASE_URL + "/organization/" + orgId).then((r) => r.data),
  saveOrder: (order) => axios.post(ORDER_BASE_URL, order).then((r) => r.data),
  deleteOrderById: (orderId) => axios.delete(ORDER_BASE_URL + "/" + orderId).then((r) => r.data),
  ...commentFunctions,
};
